<template>
    <v-card
        :loading="!loaded"
    >
        <v-card-title>
            <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text v-if="loaded">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-autocomplete
                            v-if="!landing_id"
                            :items="options.landingsForOffer"
                            v-model="item.landing_id"
                            :label="trans('fields.common.landing')"
                            :error-messages="errors.landing_id"
                            @input="clearError('landing_id')"
                            @change="clearError('landing_id')"
                        >
                            <template v-slot:item="data">
                                <template>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.text"></v-list-item-title>
                                        <v-list-item-subtitle v-if="data.item.disabled">{{ trans('pages.landing.offerExists') }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-if="!cpa_network_id"
                            :items="options.cpaNetworks"
                            v-model="item.cpa_network_id"
                            :label="trans('fields.common.cpaNetwork')"
                            :error-messages="errors.link_id"
                            @input="clearError('link_id')"
                            @change="clearError('link_id')"
                        >
                            <template v-slot:append-outer>
                                <v-slide-x-reverse-transition
                                    mode="out-in"
                                >
                                    <v-icon @click="cpaNetworkDialogShow" color="primary">mdi-plus</v-icon>
                                </v-slide-x-reverse-transition>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                            v-if="item.cpa_network_id"
                            :items="options.links"
                            :key="item.cpa_network_id"
                            v-model="item.link_id"
                            :label="trans('fields.common.link')"
                            :error-messages="errors.link_id"
                            @input="clearError('link_id')"
                            @change="clearError('link_id')"
                        >
                            <template v-slot:item="data">
                                <v-list-item-content>
                                    <v-list-item-title v-text="data.item.text"></v-list-item-title>
                                    <v-list-item-subtitle v-html="data.item.url"></v-list-item-subtitle>
                                </v-list-item-content>
                            </template>
                            <template v-slot:append-outer>
                                <v-slide-x-reverse-transition
                                    mode="out-in"
                                >
                                    <v-icon @click="linkDialogShow" color="primary">mdi-plus</v-icon>
                                </v-slide-x-reverse-transition>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-dialog v-model="cpaNetworkDialog" max-width="800px">
                        <cpa-network-form
                            v-if="cpaNetworkDialogLoaded"
                            v-on:saved="cpaNetworkDialogOnSave"
                            v-on:close="cpaNetworkDialogClose"
                        />
                    </v-dialog>
                    <v-dialog v-model="linkDialog" max-width="800px">
                        <v-tabs
                                v-if="$store.getters.user.userCpaIntegrations[item.cpa_network_id]"
                                background-color="primary"
                                dark
                        >
                            <v-tab>
                                {{trans('pages.link.form.manual')}}
                            </v-tab>
                            <v-tab>
                                {{trans('pages.link.form.byApi')}}
                            </v-tab>
                            <v-tab-item>
                                <link-form
                                        v-if="linkDialogLoaded"
                                        :cpa_network_id="item.cpa_network_id"
                                        :offer_id="item.offer_id"
                                        v-on:saved="linkDialogOnSave"
                                        v-on:close="linkDialogClose"
                                />
                            </v-tab-item>
                            <v-tab-item>
                                <link-vendor-lock-form
                                        v-if="linkDialogLoaded"
                                        :cpa_network_id="item.cpa_network_id"
                                        :offer_id="item.offer_id"
                                        v-on:saved="linkDialogOnSave"
                                        v-on:close="linkDialogClose"
                                />
                            </v-tab-item>
                        </v-tabs>
                        <link-form
                                v-else-if="linkDialogLoaded"
                                :cpa_network_id="item.cpa_network_id"
                                :offer_id="item.offer_id"
                                v-on:saved="linkDialogOnSave"
                                v-on:close="linkDialogClose"
                        />
                    </v-dialog>

                </v-row>
            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn color="primary" outlined @click="save">{{ trans('buttons.common.add') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";

    export default {
        name: "OfferAppearanceForm",
        mixins: [formValidate],
        components: {
            CpaNetworkForm: () => import('@/components/user/cpaNetwork/CpaNetworkForm'),
            LinkForm: () => import('@/components/user/link/LinkForm'),
            LinkVendorLockForm: () => import('@/components/user/link/LinkVendorLockForm'),
        },
        props: {
            landing_id: {
                type: [Number, undefined]
            },
            offer_id: {
                type: [Number, undefined]
            },
            cpa_network_id: {
                type: [Number, undefined]
            },
        },
        data(){
            return {
                options: {},

                storeRoute: 'user.offerAppearances.store',

                item: {
                    offer_id: this.offer_id,
                    cpa_network_id: this.cpa_network_id,
                    link_id: null,
                },
                links: [],


                loaded: false,

                cpaNetworkDialog: false,
                cpaNetworkDialogLoaded: false,
                linkDialog: false,
                linkDialogLoaded: false,
            }
        },
        created() {
            this.load();
        },
        watch: {
            'item.cpa_network_id'(nv, ov) {
                if (nv !== ov){
                    this.item.link_id = null;
                    this.loadLinks();
                }
            },
        },
        methods: {
            load(){
                this.loadOptions();
                if (this.item.cpa_network_id) this.loadLinks();
            },
            save(){
                axios.post(this.route(this.storeRoute), this.item).then( response => {
                    this.$emit('saved', response.data);
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                }).catch (err => {
                    this.errors = err.response.data.errors;
                    console.log(err.response.data);
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                })
            },
            loadOptions(){
                let needLoad = {};
                needLoad.cpaNetworks = true;
                needLoad.landingsForOffer = {
                    offer_id: this.item.offer_id,
                };
                if (Object.keys(needLoad).length){
                    axios.post(this.route('user.options'), needLoad).then( response => {
                        this.options = {
                            ...this.options,
                            ...response.data
                        }
                    })
                    this.loaded = true;
                }
            },
            loadLinks(){
                if (this.item.cpa_network_id){
                    axios.post(this.route('user.options'), {
                        links: {
                            offer_id: this.item.offer_id,
                            cpa_network_id: this.item.cpa_network_id,
                        }
                    }).then(response => {
                        this.options = {
                            ...this.options,
                            ...response.data
                        }
                    }).catch(err => {
                        console.log(err);
                    })
                }
            },
            cpaNetworkDialogShow(){
                this.cpaNetworkDialogLoaded = true;
                this.cpaNetworkDialog = true;
            },
            cpaNetworkDialogClose(){
                this.cpaNetworkDialog = false;
                setTimeout(() => {
                    this.cpaNetworkDialogLoaded = false;
                }, 300);
            },
            cpaNetworkDialogOnSave(id){
                this.cpaNetworkDialogClose();
                this.loadOptions(true);
                this.item.cpa_network_id = id;
                this.$emit('cpaNetworkSaved')
            },
            linkDialogShow(){
                this.linkDialogLoaded = true;
                this.linkDialog = true;
            },
            linkDialogClose(){
                this.linkDialog = false;
                setTimeout(() => {
                    this.linkDialogLoaded = false;
                }, 300);
            },
            linkDialogOnSave(id){
                this.linkDialogClose();
                this.loadOptions(true);
                this.loadLinks();
                this.item.link_id = id;
                this.$emit('linkSaved')
            },
        },
        computed: {
            formTitle() {
                return this.trans('pages.offer.addToLanding');
            },
        }
    }
</script>
